export const titleLists = {
  "base": "设备设施物联网",
  "main": "电梯物联网历史",
  "malfunction": "故障历史",
  "alarm": "报警历史",
  "event": "报警历史",
  "elevator": "电梯事件历史",
  "terminal": "物联网终端事件历史",
  "communicate": "通话历史",
  "incident": "事件历史",
}

export const eventTypes = {
  "malfunction": "1",
  "alarm": "2",
  "event": "3",
  "elevator": "4",
  "terminal": "5",
}

export const eventstatus = {
  "0": "未确认",
  "1": "未处理",
  "2": "已处理",
}
export const eventstatusColors = {
  "0": "red",
  "1": "red",
  "2": "green",
}

export const eventOptions = [
  // { key: 1, value: "故障" },
  // { key: 2, value: "报警" },
  // { key: 3, value: "AI" },
  // { key: 4, value: "电梯事件" },
  // { key: 5, value: "物联网终端事件" },
  { key: 901, value: "报警" },
  { key: 902, value: "异常" },
  { key: 903, value: "事件" },
  { key: 904, value: "通话" },
]

export const liftEventOptions = [
  { key: 1, value: "故障" },
  { key: 2, value: "报警" },
  { key: 3, value: "AI" },
  { key: 4, value: "电梯事件" },
  { key: 5, value: "物联网终端事件" },
]

export const eventTips = {
  // 1: '故障',
  // 2: '报警',
  // 3: 'AI',
  // 4: '电梯事件',
  // 5: '物联网终端事件',
  901: '报警',
  902: '异常',
  903: '事件',
  904: '通话',
}

export const bigInfluences = [
  {key:0, value:''},
  {key:1, value:'无'},
  {key:2, value:'电梯困人'},
  {key:3, value:'停用电梯'},
  {key:4, value:'停电'},
  {key:5, value:'发电'},
  {key:6, value:'停水'},
  {key:7, value:'跑水'},
  {key:8, value:'意外伤害'},
  {key:9, value:'治安'},
  {key:10, value:'群访'},
  {key:11, value:'传染病/疫情'},
  {key:12, value:'火情'},
]

export const arriveTimes = [
  {key:0, value:'全部'},
  {key:1, value:'≤3分钟'},
  {key:2, value:'＞3分钟~<=5分钟'},
  {key:3, value:'＞5分钟'},
]

export const processTimes = [
  {key:0, value:'全部'},
  {key:1, value:'≤24小时'},
  {key:2, value:'≤三天'},
  {key:3, value:'≤一周'},
  {key:4, value:'≤十五天'},
  {key:5, value:'≤一个月'},
  {key:6, value:'≤三个月'},
  {key:7, value:'＞三个月'},
]

export const alarmTypes = [
  {key:'0901', value:'烟雾探测器'},
  {key:'0902', value:'温度探测器'},
  {key:'0903', value:'手动报警器'},
  {key:'0904', value:'消火栓按钮'},
  {key:'0905', value:'水流指示器'},
  {key:'0906', value:'消防模块'},
  {key:'0907', value:'消防总线'},
  {key:'0908', value:'气体报警'},
  {key:'08', value:'值班室人员脱岗'},
  // {key:'0998', value:''},
  {key:'0999', value:'其它'},
]

export const alarmTypesMap = {
  '0901': '烟雾探测器',
  '0902': '温度探测器',
  '0903': '手动报警器',
  '0904': '消火栓按钮',
  '0905': '水流指示器',
  '0906': '消防模块',
  '0907': '消防总线',
  '0908': '气体报警',
  '08': '值班室人员脱岗',
  '0999': '其它',
}

export const systemNames =[
  {key:1, value:'消防自动报警系统'},
  {key:2, value:'门禁系统'},
  {key:3, value:'电力监测系统'},
  {key:4, value:'照明系统'},
  {key:5, value:'空调系统'},
  {key:6, value:'设施设备监控系统'},
  {key:7, value:'电梯系统'},
  // {key:8, value:'图像监控系统'},
  // {key:9, value:'防盗报警系统'},
  {key:100, value:'其它'},
]

export const systemNamesMap = {
  "1": '消防自动报警系统',
  "2": '门禁系统',
  "3": '电力监测系统',
  "4": '照明系统',
  "5": '空调系统',
  "6": '设施设备监控系统',
  "7": '电梯系统',
  // "8": '图像监控系统',
  // "9": '防盗报警系统',
  "100": '其它',
}

export const feedbackList = [
  {key:'1', value:'误报'},
  {key:'2', value:'故障'},
  {key:'3', value:'火情'},
  {key:'4', value:'其它'},
]

export const abnormalTypes = [
  {key:"1", value:'员工状态'},
  {key:"2", value:'业户状态'},
  {key:"3", value:'车辆状态'},
  {key:"4", value:'清洁状态'},
  {key:"5", value:'绿化状态'},
  {key:"6", value:'物业状态'},
  {key:"7", value:'特约活动'},
]

export const abnormalTypesMap = {
  "1": '员工状态',
  "2": '业户状态',
  "3": '车辆状态',
  "4": '清洁状态',
  "5": '绿化状态',
  "6": '物业状态',
  "7": '特约活动',
}

export const finishSigns = [
  {key:1, value:'跟进中'},
  {key:2, value:'已完成'},
]

export const equipmentTypes = [
  {key:1, value:'消防器材'},
  {key:2, value:'工具及仪表'},
  {key:3, value:'其它器材'},
]

export const fileTypes = [
  {key:1, value:'图纸'},
  {key:2, value:'体系文件'},
  {key:3, value:'设备操作文件'},
]

export const patrolShifts = [
  {key:1, value:'早班'},
  {key:2, value:'中班'},
  {key:3, value:'晚班'},
  {key:4, value:'白班'},
  {key:5, value:'夜班'},
]

export const patrolTypes = [
  {key:0, value:'系统巡查'},
  {key:1, value:'视频巡查'},
  {key:2, value:'现场巡查'},
]

export const manageTypes = [
  {key:1,value:'巡查管理-公区'},
  {key:2,value:'巡查管理-电梯'},
  {key:3,value:'巡查管理-车场'},
  {key:4,value:'巡查管理-岗位'},
  {key:5,value:'出入管理-行人出入口'},
  {key:6,value:'出入管理-大堂'},
  {key:7,value:'出入管理-车辆出入口'},
  {key:8,value:'设施设备-设备房'},
  {key:9,value:'设施设备-重点设施设备'},
  {key:10,value:'设施设备-抄表'},
  {key:11,value:'恶劣天气-危险位置'},
  {key:12,value:'恶劣天气-项目最低位置'},
  {key:13,value:'恶劣天气-雨后积水位置'},
  {key:14,value:'重点抽查位置'},
  // {key:15,value:'随机抽查'},
]

export const timeList = [
  {"key":0,"value":"0时"},
  {"key":1,"value":"1时"},
  {"key":2,"value":"2时"},
  {"key":3,"value":"3时"},
  {"key":4,"value":"4时"},
  {"key":5,"value":"5时"},
  {"key":6,"value":"6时"},
  {"key":7,"value":"7时"},
  {"key":8,"value":"8时"},
  {"key":9,"value":"9时"},
  {"key":10,"value":"10时"},
  {"key":11,"value":"11时"},
  {"key":12,"value":"12时"},
  {"key":13,"value":"13时"},
  {"key":14,"value":"14时"},
  {"key":15,"value":"15时"},
  {"key":16,"value":"16时"},
  {"key":17,"value":"17时"},
  {"key":18,"value":"18时"},
  {"key":19,"value":"19时"},
  {"key":20,"value":"20时"},
  {"key":21,"value":"21时"},
  {"key":22,"value":"22时"},
  {"key":23,"value":"23时"},
]

export const serviceMode = { 0: "停止服务", 1: "正常运行", 2: "检修", 3: "消防返回", 4: "消防员运行", 5: "应急电源运行", 6: "地震模式", 7: "未知" }
export const carStatus = { "00": "停止", "01": "运行" }
export const carDirection = { "00": "无方向", "01": "上行", "02": "下行" }
// export const doorStatus = { "00": "未关门到位", "01": "关门到位" }
export const doorStatus = { "00": "开门", "01": "关门到位" }
export const hasPassenger = { "00": "无人", "01": "有人" }

export const recordTypes = [
  {key:1,value:'调度员'},
  {key:2,value:'项目管理者'},
  {key:3,value:'服务方案'},
  {key:4,value:'设施设备'},
  {key:5,value:'整改现场'},
  {key:6,value:'其它'},
]

export const userTypes = [
  {key:4,value:'督导管理员'},
  {key:5,value:'项目管理员'},
]

export const managerTypes = [
  {key:1,value:'项目负责人'},
  {key:2,value:'其他'},
]


export const processTypes = [
  {key:1, value:'处理中'},
  {key:2, value:'待验证'},
  {key:3, value:'已完成'},
]