<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 30%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
<!--            <a-form-model-item>-->
<!--              <a-button-group style="margin-left: 15%">-->
<!--                <a-button :type="buttonTypes.value1" @click="changeButton(0)">全部</a-button>-->
<!--                <a-button :type="buttonTypes.value2" @click="changeButton(1)">图纸</a-button>-->
<!--                <a-button :type="buttonTypes.value3" @click="changeButton(2)">体系文件</a-button>-->
<!--                <a-button :type="buttonTypes.value4" @click="changeButton(3)">设备操作文件</a-button>-->
<!--              </a-button-group>-->
<!--            </a-form-model-item>-->
            <div class="query-btns" style="float: right;width: 30%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="filetype" slot-scope="value, record">
            <span>{{fileTypes[value-1]?fileTypes[value-1].value:''}}</span>
          </span>
          <span slot="monitorpointname" slot-scope="value, record">
            <a @click="getAllTableData(record)">{{value}}</a>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button @click="showModal('detail',value,record)" type="link">详情</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    </div>
  </page-container>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import pagination from "@/mixins/pagination";
import addOrEditModal from "V/patrols/patrolsSetting/storeFiles/addOrEditModal";
import {getApplicationFileByCondition} from "A/patrol";
import {fileTypes} from "@/json/wlwhistory";
import {getMonitorPointListByCondition} from "A/monitoring";
import deptselect from "@/mixins/deptselect";

export default {
  name: "applicationFile",
  mixins: [deptselect,pagination],
  components:{
    addOrEditModal,
  },
  data(){
    return{
      moment,
      showAdvanced: false,
      fileTypes,
      buttonTypes:{'value1':'','value2':'primary','value3':'','value4':''},
      queryParams: {
        monitorpointnum: '',
        monitorpointname:'',
        address: '',
        monitorpointid:'',
        filename:'',
        fileurl:'',
        filetype:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' }
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      index:1,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "应用文件"
    },
    breadcrumb() {
      const pages = [{name:"管理资料",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.getTableData(true)
    this.initDeptOptionsAll();
  },
  methods: {
    getAllTableData(record){
      this.currentPage = 1;
      this.tableColumns=[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '文件名',
          dataIndex: 'filename',
          key: 'filename',
          ellipsis: true,
        },
        // {
        //   title: '文件类型',
        //   dataIndex: 'filetype',
        //   key: 'filetype',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'filetype' }
        // },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ]
      this.index=0
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        filetype:null,
        monitorpointnum:record.monitorpointnum,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getApplicationFileByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      if(this.index==1){
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
          {
            title: '文件名',
            dataIndex: 'filename',
            key: 'filename',
            ellipsis: true,
          },
          // {
          //   title: '文件类型',
          //   dataIndex: 'filetype',
          //   key: 'filetype',
          //   ellipsis: true,
          //   scopedSlots: { customRender: 'filetype' }
          // },
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 70,
            scopedSlots: { customRender: 'operation' }
          },
        ]
        this.tableLoading = true;
        let params = {
          ...this.queryParams,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getApplicationFileByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        this.tableColumns=[
          {
            title: '项目名称',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
            scopedSlots: { customRender: 'monitorpointname' }
          },
          {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
          },
        ]
        let params = {
          ...this.queryParams,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMonitorPointListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    changeButton(index){
      if(index==0){
        this.buttonTypes.value1='primary'
        this.buttonTypes.value2=''
        this.buttonTypes.value3=''
        this.buttonTypes.value4=''
        this.queryParams.filetype=null
        this.index=0
        this.getTableData(true)
      }else if(index==1){
        this.buttonTypes.value1=''
        this.buttonTypes.value2='primary'
        this.buttonTypes.value3=''
        this.buttonTypes.value4=''
        this.queryParams.filetype=1
        this.index=1
        this.getTableData(true)
      } else if(index==2){
        this.buttonTypes.value1=''
        this.buttonTypes.value2=''
        this.buttonTypes.value3='primary'
        this.buttonTypes.value4=''
        this.queryParams.filetype=2
        this.index=1
        this.getTableData(true)
      }else if(index==3){
        this.buttonTypes.value1=''
        this.buttonTypes.value2=''
        this.buttonTypes.value3=''
        this.buttonTypes.value4='primary'
        this.queryParams.filetype=3
        this.index=1
        this.getTableData(true)
      }
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.userdepidCascaderSelected=[]
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
  },
}
</script>

<style scoped>

</style>