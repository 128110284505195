// 基础功能
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
    preApi = '/mock';
}

//交接班信息
export function addShiftData(params) {
    return http({
        url: `${preApi}/patrol/addShiftData`,
        method: "post",
        data: params
    })
}
export function modifyShiftData(params) {
    return http({
        url: `${preApi}/patrol/modifyShiftData`,
        method: "post",
        data: params
    })
}
export function deleteShiftData(params) {
    return http({
        url: `${preApi}/patrol/deleteShiftData`,
        method: "post",
        data: params
    })
}
export function getShiftDataListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getShiftDataListByCondition`,
        method: "post",
        data: params
    })
}

export function getShiftData(params) {
    return http({
        url: `${preApi}/patrol/getShiftData`,
        method: "post",
        data: params
    })
}

export function exportFireAlarmRecord(params) {
    params.filename = '消防值班记录表.docx';
    return http({
        url: `${preApi}/patrol/exportFireAlarmRecord`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}

//项目底图信息
export function addBasemap(params) {
    return http({
        url: `${preApi}/patrol/addBasemap`,
        method: "post",
        data: params
    })
}
export function modifyBasemap(params) {
    return http({
        url: `${preApi}/patrol/modifyBasemap`,
        method: "post",
        data: params
    })
}
export function deleteBasemap(params) {
    return http({
        url: `${preApi}/patrol/deleteBasemap`,
        method: "post",
        data: params
    })
}
export function getBasemapListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getBasemapListByCondition`,
        method: "post",
        data: params
    })
}

//绿化区域图片
export function addAfforestAreaPic(params) {
    return http({
        url: `${preApi}/patrol/addAfforestAreaPic`,
        method: "post",
        data: params
    })
}
export function modifyAfforestAreaPic(params) {
    return http({
        url: `${preApi}/patrol/modifyAfforestAreaPic`,
        method: "post",
        data: params
    })
}
export function deleteAfforestAreaPic(params) {
    return http({
        url: `${preApi}/patrol/deleteAfforestAreaPic`,
        method: "post",
        data: params
    })
}
export function getAfforestAreaPicListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getAfforestAreaPicListByCondition`,
        method: "post",
        data: params
    })
}

//项目图片信息
export function addSceneInfo(params) {
    return http({
        url: `${preApi}/patrol/addSceneInfo`,
        method: "post",
        data: params
    })
}
export function modifySceneInfo(params) {
    return http({
        url: `${preApi}/patrol/modifySceneInfo`,
        method: "post",
        data: params
    })
}
export function deleteSceneInfo(params) {
    return http({
        url: `${preApi}/patrol/deleteSceneInfo`,
        method: "post",
        data: params
    })
}
export function getSceneInfoListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getSceneInfoListByCondition`,
        method: "post",
        data: params
    })
}

//器材清单信息
export function addEquipmentList(params) {
    return http({
        url: `${preApi}/patrol/addEquipmentList`,
        method: "post",
        data: params
    })
}
export function modifyEquipmentList(params) {
    return http({
        url: `${preApi}/patrol/modifyEquipmentList`,
        method: "post",
        data: params
    })
}
export function deleteEquipmentList(params) {
    return http({
        url: `${preApi}/patrol/deleteEquipmentList`,
        method: "post",
        data: params
    })
}
export function getEquipmentListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEquipmentListByCondition`,
        method: "post",
        data: params
    })
}

//工程器材清单信息
export function addEngineerEquipmentList(params) {
    return http({
        url: `${preApi}/patrol/addEngineerEquipmentList`,
        method: "post",
        data: params
    })
}
export function modifyEngineerEquipmentList(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerEquipmentList`,
        method: "post",
        data: params
    })
}
export function deleteEngineerEquipmentList(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerEquipmentList`,
        method: "post",
        data: params
    })
}
export function getEngineerEquipmentListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerEquipmentListByCondition`,
        method: "post",
        data: params
    })
}

//器材清单信息
export function addApplicationFile(params) {
    return http({
        url: `${preApi}/patrol/addApplicationFile`,
        method: "post",
        data: params
    })
}
export function modifyApplicationFile(params) {
    return http({
        url: `${preApi}/patrol/modifyApplicationFile`,
        method: "post",
        data: params
    })
}
export function deleteApplicationFile(params) {
    return http({
        url: `${preApi}/patrol/deleteApplicationFile`,
        method: "post",
        data: params
    })
}
export function getApplicationFileByCondition(params) {
    return http({
        url: `${preApi}/patrol/getApplicationFileByCondition`,
        method: "post",
        data: params
    })
}

//巡查方案信息
export function addPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/addPatrolScheme`,
        method: "post",
        data: params
    })
}
export function modifyPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/modifyPatrolScheme`,
        method: "post",
        data: params
    })
}
export function deletePatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/deletePatrolScheme`,
        method: "post",
        data: params
    })
}
export function getPatrolSchemeListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolSchemeListByCondition`,
        method: "post",
        data: params
    })
}

//巡查方案模板
export function addPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/addPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function modifyPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/modifyPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function resetPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/resetPatrolScheme`,
        method: "post",
        data: params
    })
}
export function resetPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/resetPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function resetPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/resetPatrolRecord`,
        method: "post",
        data: params
    })
}
export function deletePatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/deletePatrolTemplate`,
        method: "post",
        data: params
    })
}
export function getPatrolTemplateListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolTemplateListByCondition`,
        method: "post",
        data: params
    })
}

export function addPatrolSchemeData(params) {
    return http({
        url: `${preApi}/patrol/addPatrolSchemeData`,
        method: "post",
        data: params
    })
}
export function modifyPatrolSchemeData(params) {
    return http({
        url: `${preApi}/patrol/modifyPatrolSchemeData`,
        method: "post",
        data: params
    })
}
export function deletePatrolSchemeData(params) {
    return http({
        url: `${preApi}/patrol/deletePatrolSchemeData`,
        method: "post",
        data: params
    })
}
export function getPatrolSchemeDataListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolSchemeDataListByCondition`,
        method: "post",
        data: params
    })
}

export function addPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/addPatrolRecord`,
        method: "post",
        data: params
    })
}
export function modifyPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/modifyPatrolRecord`,
        method: "post",
        data: params
    })
}
export function deletePatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/deletePatrolRecord`,
        method: "post",
        data: params
    })
}
export function getPatrolRecordListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolRecordListByCondition`,
        method: "post",
        data: params
    })
}
export function addPatrolRecordData(params) {
    return http({
        url: `${preApi}/patrol/addPatrolRecordData`,
        method: "post",
        data: params
    })
}
export function modifyPatrolRecordData(params) {
    return http({
        url: `${preApi}/patrol/modifyPatrolRecordData`,
        method: "post",
        data: params
    })
}
export function deletePatrolRecordData(params) {
    return http({
        url: `${preApi}/patrol/deletePatrolRecordData`,
        method: "post",
        data: params
    })
}
export function getPatrolRecordDataListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolRecordDataListByCondition`,
        method: "post",
        data: params
    })
}

//获取巡查记录模板
export function getPatrolSchemeTemplate(params) {
    return http({
        url: `${preApi}/patrol/getPatrolSchemeTemplate`,
        method: "post",
        data: params
    })
}

//督导工作记录
export function addSupervise(params) {
    return http({
        url: `${preApi}/supervise/addSupervise`,
        method: "post",
        data: params
    })
}
export function updateSupervise(params) {
    return http({
        url: `${preApi}/supervise/updateSupervise`,
        method: "post",
        data: params
    })
}
export function deleteSupervise(params) {
    return http({
        url: `${preApi}/supervise/deleteSupervise`,
        method: "post",
        data: params
    })
}
export function getSuperviseListByCondition(params) {
    return http({
        url: `${preApi}/supervise/getSuperviseListByCondition`,
        method: "post",
        data: params
    })
}

//品质改进单
export function addImproveOrder(params) {
    return http({
        url: `${preApi}/supervise/addImproveOrder`,
        method: "post",
        data: params
    })
}
export function updateImproveOrder(params) {
    return http({
        url: `${preApi}/supervise/updateImproveOrder`,
        method: "post",
        data: params
    })
}
export function deleteImproveOrder(params) {
    return http({
        url: `${preApi}/supervise/deleteImproveOrder`,
        method: "post",
        data: params
    })
}
export function getImproveOrderListByCondition(params) {
    return http({
        url: `${preApi}/supervise/getImproveOrderListByCondition`,
        method: "post",
        data: params
    })
}
export function getImproveOrderStatistics(params) {
    return http({
        url: `${preApi}/supervise/getImproveOrderStatistics`,
        method: "post",
        data: params
    })
}
export function exportImproveOrderByCondition(params) {
    return http({
        url: `${preApi}/supervise/exportImproveOrderByCondition`,
        method: "post",
        data: params
    })
}
export function exportImproveOrderExcel(params) {
    return http({
        url: `${preApi}/supervise/exportImproveOrderExcel`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}
export function exportImproveOrderWord(params) {
    params.filename = '品质改进单.docx';
    return http({
        url: `${preApi}/supervise/exportImproveOrderWord`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}

//抄表信息
export function addMeterReading(params) {
    return http({
        url: `${preApi}/patrol/addMeterReading`,
        method: "post",
        data: params
    })
}
export function modifyMeterReading(params) {
    return http({
        url: `${preApi}/patrol/modifyMeterReading`,
        method: "post",
        data: params
    })
}
export function deleteMeterReading(params) {
    return http({
        url: `${preApi}/patrol/deleteMeterReading`,
        method: "post",
        data: params
    })
}
export function getMeterReadingListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getMeterReadingListByCondition`,
        method: "post",
        data: params
    })
}
export function exportMeterReadingListByCondition(params) {
    return http({
        url: `${preApi}/patrol/exportMeterReadingListByCondition`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}

//物业巡查
export function addPropertyPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/addPropertyPatrolRecord`,
        method: "post",
        data: params
    })
}
export function modifyPropertyPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/modifyPropertyPatrolRecord`,
        method: "post",
        data: params
    })
}
export function deletePropertyPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/deletePropertyPatrolRecord`,
        method: "post",
        data: params
    })
}
export function getPropertyPatrolRecordListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPropertyPatrolRecordListByCondition`,
        method: "post",
        data: params
    })
}
export function addPropertyInspection(params) {
    return http({
        url: `${preApi}/patrol/addPropertyInspection`,
        method: "post",
        data: params
    })
}
export function modifyPropertyInspection(params) {
    return http({
        url: `${preApi}/patrol/modifyPropertyInspection`,
        method: "post",
        data: params
    })
}
export function deletePropertyInspection(params) {
    return http({
        url: `${preApi}/patrol/deletePropertyInspection`,
        method: "post",
        data: params
    })
}
export function getPropertyInspectionListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPropertyInspectionListByCondition`,
        method: "post",
        data: params
    })
}

export function getPatrolDailyInspectionLine(params) {
    return http({
        url: `${preApi}/patrol/getPatrolDailyInspectionLine`,
        method: "post",
        data: params
    })
}
export function getPatrolDailyInspectionLineTemplate(params) {
    return http({
        url: `${preApi}/patrol/getPatrolDailyInspectionLineTemplate`,
        method: "post",
        data: params
    })
}
export function getPropertyInspectionUsernameList(params) {
    return http({
        url: `${preApi}/patrol/getPropertyInspectionUsernameList`,
        method: "post",
        data: params
    })
}
export function getPropertyInspectionItemRecordListByOrderId(params) {
    return http({
        url: `${preApi}/patrol/getPropertyInspectionItemRecordListByOrderId`,
        method: "post",
        data: params
    })
}
export function getPropertyInspectionPicByRecordId(params) {
    return http({
        url: `${preApi}/patrol/getPropertyInspectionPicByRecordId`,
        method: "post",
        data: params
    })
}

export function getPatrolDailyInspectionListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolDailyInspectionListByCondition`,
        method: "post",
        data: params
    })
}


export function addPropertyInspectionPoint(params) {
    return http({
        url: `${preApi}/patrol/addPropertyInspectionPoint`,
        method: "post",
        data: params
    })
}
export function modifyPropertyInspectionPoint(params) {
    return http({
        url: `${preApi}/patrol/modifyPropertyInspectionPoint`,
        method: "post",
        data: params
    })
}
export function deletePropertyInspectionPoint(params) {
    return http({
        url: `${preApi}/patrol/deletePropertyInspectionPoint`,
        method: "post",
        data: params
    })
}
export function getPropertyInspectionPointListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPropertyInspectionPointListByCondition`,
        method: "post",
        data: params
    })
}
export function getPropertyInspectionPointInfoById(params) {
    return http({
        url: `${preApi}/patrol/getPropertyInspectionPointInfoById`,
        method: "post",
        data: params
    })
}
//导出巡查点信息excel
export function exportPropertyInspectionPointExcel(params) {
    return http({
        url: `${preApi}/patrol/exportPropertyInspectionPointExcel`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}
//导出巡查点标识卡
export function exportPropertyInspectionPointIDCardWord(params) {
    params.filename = '巡查点标识卡.docx';
    return http({
        url: `${preApi}/patrol/exportPropertyInspectionPointIDCardWord`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}


//人工巡查线路
export function addPatrolDailyTemplate(params) {
    return http({
        url: `${preApi}/patrol/addPatrolDailyTemplate`,
        method: "post",
        data: params
    })
}
export function modifyPatrolDailyTemplate(params) {
    return http({
        url: `${preApi}/patrol/modifyPatrolDailyTemplate`,
        method: "post",
        data: params
    })
}
export function deletePatrolDailyTemplate(params) {
    return http({
        url: `${preApi}/patrol/deletePatrolDailyTemplate`,
        method: "post",
        data: params
    })
}
export function getPatrolDailyTemplateListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolDailyTemplateListByCondition`,
        method: "post",
        data: params
    })
}
export function getPatrolDailyHistoryListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getPatrolDailyHistoryListByCondition`,
        method: "post",
        data: params
    })
}
//导出人工巡查记录excel
export function exportPropertyInspectionExcel(params) {
    return http({
        url: `${preApi}/log/exportPropertyInspectionExcel`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}

//工程交接班信息
export function addEngineerShiftData(params) {
    return http({
        url: `${preApi}/patrol/addEngineerShiftData`,
        method: "post",
        data: params
    })
}
export function modifyEngineerShiftData(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerShiftData`,
        method: "post",
        data: params
    })
}
export function deleteEngineerShiftData(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerShiftData`,
        method: "post",
        data: params
    })
}
export function getEngineerShiftDataListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerShiftDataListByCondition`,
        method: "post",
        data: params
    })
}
export function getEngineerShiftData(params) {
    return http({
        url: `${preApi}/patrol/getEngineerShiftData`,
        method: "post",
        data: params
    })
}

//工程巡查方案信息
export function addEngineerPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/addEngineerPatrolScheme`,
        method: "post",
        data: params
    })
}
export function modifyEngineerPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerPatrolScheme`,
        method: "post",
        data: params
    })
}
export function deleteEngineerPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerPatrolScheme`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolSchemeListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolSchemeListByCondition`,
        method: "post",
        data: params
    })
}

//工程巡查方案模板
export function addEngineerPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/addEngineerPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function modifyEngineerPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function resetEngineerPatrolScheme(params) {
    return http({
        url: `${preApi}/patrol/resetEngineerPatrolScheme`,
        method: "post",
        data: params
    })
}
export function resetEngineerPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/resetEngineerPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function resetEngineerPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/resetEngineerPatrolRecord`,
        method: "post",
        data: params
    })
}
export function deleteEngineerPatrolTemplate(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerPatrolTemplate`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolTemplateListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolTemplateListByCondition`,
        method: "post",
        data: params
    })
}

export function addEngineerPatrolSchemeData(params) {
    return http({
        url: `${preApi}/patrol/addEngineerPatrolSchemeData`,
        method: "post",
        data: params
    })
}
export function modifyEngineerPatrolSchemeData(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerPatrolSchemeData`,
        method: "post",
        data: params
    })
}
export function deleteEngineerPatrolSchemeData(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerPatrolSchemeData`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolSchemeDataListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolSchemeDataListByCondition`,
        method: "post",
        data: params
    })
}

export function addEngineerPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/addEngineerPatrolRecord`,
        method: "post",
        data: params
    })
}
export function modifyEngineerPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerPatrolRecord`,
        method: "post",
        data: params
    })
}
export function deleteEngineerPatrolRecord(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerPatrolRecord`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolRecordListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolRecordListByCondition`,
        method: "post",
        data: params
    })
}
export function addEngineerInspection(params) {
    return http({
        url: `${preApi}/patrol/addEngineerInspection`,
        method: "post",
        data: params
    })
}
export function modifyEngineerInspection(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerInspection`,
        method: "post",
        data: params
    })
}
export function deleteEngineerInspection(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerInspection`,
        method: "post",
        data: params
    })
}
export function getEngineerInspectionListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerInspectionListByCondition`,
        method: "post",
        data: params
    })
}
export function getEngineerInspectionUsernameList(params) {
    return http({
        url: `${preApi}/patrol/getEngineerInspectionUsernameList`,
        method: "post",
        data: params
    })
}
export function getEngineerInspectionItemRecordListByOrderId(params) {
    return http({
        url: `${preApi}/patrol/getEngineerInspectionItemRecordListByOrderId`,
        method: "post",
        data: params
    })
}
// 查询巡查图片信息列表
export function getEngineerInspectionPicByRecordId(params) {
    return http({
        url: `${preApi}/patrol/getEngineerInspectionPicByRecordId`,
        method: "post",
        data: params
    })
}
export function addEngineerPatrolRecordData(params) {
    return http({
        url: `${preApi}/patrol/addEngineerPatrolRecordData`,
        method: "post",
        data: params
    })
}
export function modifyEngineerPatrolRecordData(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerPatrolRecordData`,
        method: "post",
        data: params
    })
}
export function deleteEngineerPatrolRecordData(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerPatrolRecordData`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolRecordDataListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolRecordDataListByCondition`,
        method: "post",
        data: params
    })
}

//获取工程巡查记录模板
export function getEngineerPatrolSchemeTemplate(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolSchemeTemplate`,
        method: "post",
        data: params
    })
}

export function getEngineerPatrolDailyInspectionLine(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolDailyInspectionLine`,
        method: "post",
        data: params
    })
}

export function getEngineerDailyInspectionLineTemplate(params) {
    return http({
        url: `${preApi}/patrol/getEngineerDailyInspectionLineTemplate`,
        method: "post",
        data: params
    })
}

export function getEngineerPatrolDailyInspectionListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolDailyInspectionListByCondition`,
        method: "post",
        data: params
    })
}


//工程人工巡查线路
export function addEngineerPatrolDailyTemplate(params) {
    return http({
        url: `${preApi}/patrol/addEngineerPatrolDailyTemplate`,
        method: "post",
        data: params
    })
}
export function modifyEngineerPatrolDailyTemplate(params) {
    return http({
        url: `${preApi}/patrol/modifyEngineerPatrolDailyTemplate`,
        method: "post",
        data: params
    })
}
export function deleteEngineerPatrolDailyTemplate(params) {
    return http({
        url: `${preApi}/patrol/deleteEngineerPatrolDailyTemplate`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolDailyTemplateListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolDailyTemplateListByCondition`,
        method: "post",
        data: params
    })
}
export function getEngineerPatrolDailyHistoryListByCondition(params) {
    return http({
        url: `${preApi}/patrol/getEngineerPatrolDailyHistoryListByCondition`,
        method: "post",
        data: params
    })
}