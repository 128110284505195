<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="500" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams"  class="query-form" layout="inline" :rules="formRules" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="文件名" prop="filename" required style="width: 80%">
          <a-input v-model.trim="queryParams.filename" placeholder="请选择"  :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
<!--        <a-form-model-item label="文件类型" prop="filetype" style="width: 80%">-->
<!--          <a-select placeholder="请选择" v-model="queryParams.filetype" :disabled="showType=='detail'">-->
<!--            <a-select-option v-for="item in fileTypes" :key="item.key">-->
<!--              {{ item.value }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="文件地址" prop="fileurl" style="width: 80%">
<!--          <a-input v-model="queryParams.fileurl" :disabled="showType=='detail'"></a-input>-->
          <a-upload
              :disabled="showType=='detail'"
              style="width: 220px"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadFiles"
              action="/upload"
              :file-list="fileUrlList"
              @change="fileUrlUploaded">
            <a-button :disabled="showType=='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {getMonitorPointNameList} from "A/monitoring";
import {addApplicationFile, modifyApplicationFile} from "A/patrol";
import {mapGetters, mapState} from "vuex";
import {fileTypes} from "@/json/wlwhistory";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      fileTypes,
      monitorpointNameList: [],
      fileUrlList:null,
      queryParams:{
        applicationfileid:'',
        monitorpointid:'',
        monitorpointnum:'',
        monitorpointname:'',
        address:'',
        fileurl:'',
        filename:'',
        filetype:'',
      },
      formRules: {
        filename: [{required: true, message: '请输入文件名'}],
      },
    }
  },
  computed: {
    ...mapGetters(['uploadHeaders', 'uploadFiles']),
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.queryParams.filetype=this.detailData
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.getMonitorpointName()
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item[0].monitorpointnum
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for (let key in this.queryParams) {
            this.queryParams[key] = this.detailData[key] || '';
          }
          if (this.detailData.fileurl) {
            let nameIndex = this.detailData.fileurl.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.fileurl.lastIndexOf('\\');
            }
            let fileName = this.detailData.fileurl.substr(nameIndex + 1);
            this.fileUrlList = [{
              uid: 'fileuid',
              name: fileName,
              status: 'done',
              url: this.detailData.fileurl,
            }];
          }
        })
      }
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
      this.fileUrlList=null
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addApplicationFile(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifyApplicationFile(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('支持上传的图片类型为jpg/png格式!');
      }
      const isLt1M = file.size / 1024 /1024 < 2;
      if (!isLt1M) {
        this.$message.error('图片大小最大支持上传2MB!');
      }
      return isJpgOrPng && isLt1M;
    },
    fileUrlUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.queryParams.fileurl = file.response.urlPath;
        }
        return file;
      });
      this.fileUrlList = fileList;
    },
  }
}
</script>