// 基础功能
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
    preApi = '/mock';
}

// 添加监控设备
export function addMonitorinfo(params) {
    return http({
        url: `${preApi}/monitorinfo/addMonitorinfo`,
        method: "post",
        data: params
    })
}

// 修改监控设备
export function modifyMonitorinfo(params) {
    return http({
        url: `${preApi}/monitorinfo/modifyMonitorinfo`,
        method: "post",
        data: params
    })
}

// 设备查询
export function getMonitorinfoListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getMonitorinfoListByCondition`,
        method: "post",
        data: params
    })
}

// 设备列表查询
export function getMonitorinfoMenu(params) {
    return http({
        url: `${preApi}/monitorinfo/getMonitorinfoMenu`,
        method: "post",
        data: params
    })
}

// 删除设备
export function deleteMonitorinfo(params) {
    return http({
        url: `${preApi}/monitorinfo/deleteMonitorinfo`,
        method: "post",
        data: params
    })
}

//连接master控制端
export function master_link(params){
    return http({
        url: `${preApi}/thirdpart/master_link`,
        method: "post",
        data: params,
    })
}

//添加项目档案
export function addMonitorPoint(params) {
    return http({
        url: `${preApi}/monitorpoint/addMonitorPoint`,
        method: "post",
        data: params
    })
}
//更新项目档案
export function modifyMonitorPoint(params) {
    return http({
        url: `${preApi}/monitorpoint/modifyMonitorPoint`,
        method: "post",
        data: params
    })
}
export function modifyMonitorPointData(params) {
    return http({
        url: `${preApi}/monitorpoint/modifyMonitorPointData`,
        method: "post",
        data: params
    })
}
export function modifyMonitorPointSystemRole(params) {
    return http({
        url: `${preApi}/monitorpoint/modifyMonitorPointSystemRole`,
        method: "post",
        data: params
    })
}

//删除项目档案
export function deleteMonitorPoint(params) {
    return http({
        url: `${preApi}/monitorpoint/deleteMonitorPoint`,
        method: "post",
        data: params
    })
}
//查询项目档案
export function getMonitorPointListByCondition(params) {
    return http({
        url: `${preApi}/monitorpoint/getMonitorPointListByCondition`,
        method: "post",
        data: params
    })
}
//查询项目所属片区
export function getMonitorPointChangeList(params) {
    return http({
        url: `${preApi}/monitorpoint/getMonitorPointChangeList`,
        method: "post",
        data: params
    })
}
//查询项目所属片区
export function modifyMonitorPointParentUserDep(params) {
    return http({
        url: `${preApi}/monitorpoint/modifyMonitorPointParentUserDep`,
        method: "post",
        data: params
    })
}

export function getMonitorPointPhoneById(params) {
    return http({
        url: `${preApi}/monitorpoint/getMonitorPointPhoneById`,
        method: "post",
        data: params
    })
}

//根据ID查询项目档案
export function getMonitorPointinfoById(params) {
    return http({
        url: `${preApi}/monitorpoint/getMonitorPointinfoById`,
        method: "post",
        data: params
    })
}
//按条件查询监控客户端
export function getPointMonitorinfoListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getPointMonitorinfoListByCondition`,
        method: "post",
        data: params
    })
}
//按ID查询监控客户端联系
export function getPointMonitorByPointMonitorId(params) {
    return http({
        url: `${preApi}/monitorpoint/getPointMonitorByPointMonitorId`,
        method: "post",
        data: params
    })
}

//添加项目参数
export function addPointParams(params) {
    return http({
        url: `${preApi}/monitorpoint/addPointParams`,
        method: "post",
        data: params
    })
}
//修改项目参数
export function modifyPointParams(params) {
    return http({
        url: `${preApi}/monitorpoint/modifyPointParams`,
        method: "post",
        data: params
    })
}
//获取项目参数列表
export function getPointParamsListByCondition(params) {
    return http({
        url: `${preApi}/monitorpoint/getPointParamsListByCondition`,
        method: "post",
        data: params
    })
}
//获取项目参数
export function getPointParamsList(params) {
    return http({
        url: `${preApi}/monitorpoint/getPointParamsList`,
        method: "post",
        data: params
    })
}

//大数据视图上方数据
export function getIotMaindata(params) {
    return http({
        url: `${preApi}/bigdata/getIotMaindata`,
        method: "post",
        data: params
    })
}
export function getUnhandleEventrecord(params) {
    return http({
        url: `${preApi}/event/getUnhandleEventrecord`,
        method: "post",
        data: params
    })
}
export function getUnhandleIotEventrecord(params) {
    return http({
        url: `${preApi}/event/getUnhandleIotEventrecord`,
        method: "post",
        data: params
    })
}

//获取项目名
export function getMonitorPointNameList(params) {
    return http({
        url: `${preApi}/monitorpoint/getMonitorPointNameList`,
        method: "post",
        data: params
    })
}

export function getMonitorPointNameListByCondition(params) {
    return http({
        url: `${preApi}/monitorpoint/getMonitorPointNameListByCondition`,
        method: "post",
        data: params
    })
}

export function getUserDepNameByCondition(params) {
    return http({
        url: `${preApi}/monitorpoint/getUserDepNameByCondition`,
        method: "post",
        data: params
    })
}

//获取播放视频流
export function playClients(params) {
    return http({
        url: `${preApi}/monitorinfo/playClients`,
        method: "post",
        data: params
    })
}
//开启远程控制
export function playRemote(params) {
    return http({
        url: `${preApi}/monitorinfo/playRemote`,
        method: "post",
        data: params
    })
}

//重启客户端
export function stopClients(params) {
    return http({
        url: `${preApi}/monitorinfo/stopClients`,
        method: "post",
        data: params
    })
}

//重启远控程序
export function stopRemote(params) {
    return http({
        url: `${preApi}/monitorinfo/stopRemote`,
        method: "post",
        data: params
    })
}

//获取远控端口
export function getRemotePort(params) {
    return http({
        url: `${preApi}/monitorinfo/getRemotePort`,
        method: "post",
        data: params
    })
}

// 设备组列表查询
export function getMonitorgroupListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getMonitorgroupListByCondition`,
        method: "post",
        data: params
    })
}
// 删除设备组
export function deleteMonitorgroup(params) {
    return http({
        url: `${preApi}/monitorinfo/deleteMonitorgroup`,
        method: "post",
        data: params
    })
}
// 新增设备组
export function addMonitorgroup(params) {
    return http({
        url: `${preApi}/monitorinfo/addMonitorgroup`,
        method: "post",
        data: params
    })
}
// 修改设备组
export function modifyMonitorgroup(params) {
    return http({
        url: `${preApi}/monitorinfo/modifyMonitorgroup`,
        method: "post",
        data: params
    })
}
// 查询关联设备
export function getMonitorgroupdevListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getMonitorgroupdevListByCondition`,
        method: "post",
        data: params
    })
}
// 添加关联设备
export function addMonitorgroupdev(params) {
    return http({
        url: `${preApi}/monitorinfo/addMonitorgroupdev`,
        method: "post",
        data: params
    })
}
// 删除关联设备
export function deleteMonitorgroupdev(params) {
    return http({
        url: `${preApi}/monitorinfo/deleteMonitorgroupdev`,
        method: "post",
        data: params
    })
}

// 查询版本列表
export function getMonitorversionListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getMonitorversionListByCondition`,
        method: "post",
        data: params
    })
}
// 查询版本详情
export function getMonitorversionInfo(params) {
    return http({
        url: `${preApi}/monitorinfo/getMonitorversionInfo`,
        method: "post",
        data: params
    })
}
// 添加版本
export function addMonitorversion(params) {
    return http({
        url: `${preApi}/monitorinfo/addMonitorversion`,
        method: "post",
        data: params
    })
}
// 修改版本
export function modifyMonitorversion(params) {
    return http({
        url: `${preApi}/monitorinfo/modifyMonitorversion`,
        method: "post",
        data: params
    })
}
// 修改版本
export function deleteMonitorversion(params) {
    return http({
        url: `${preApi}/monitorinfo/deleteMonitorversion`,
        method: "post",
        data: params
    })
}
// 修改设备版本
export function updateVersion(params) {
    return http({
        url: `${preApi}/monitorinfo/updateVersion`,
        method: "post",
        data: params
    })
}

// 升级远程模块版本
export function updateRemoteFile(params) {
    return http({
        url: `${preApi}/monitorinfo/updateRemoteFile`,
        method: "post",
        data: params
    })
}

// 查询网络日志
export function getNetworkLogListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getNetworkLogListByCondition`,
        method: "post",
        data: params
    })
}

export function getNetworkMonitorPoint(params) {
    return http({
        url: `${preApi}/monitorinfo/getNetworkMonitorPoint`,
        method: "post",
        data: params
    })
}

export function getNetworkLogStatistics(params) {
    return http({
        url: `${preApi}/monitorinfo/getNetworkLogStatistics`,
        method: "post",
        data: params
    })
}

export function getAbnormalStreamListByCondition(params) {
    return http({
        url: `${preApi}/monitorinfo/getAbnormalStreamListByCondition`,
        method: "post",
        data: params
    })
}

export function deleteAbnormalStream(params) {
    return http({
        url: `${preApi}/monitorinfo/deleteAbnormalStream`,
        method: "post",
        data: params
    })
}

//添加场景保养信息
export function addSceneMaintain(params) {
    return http({
        url: `${preApi}/scene/addSceneMaintain`,
        method: "post",
        data: params
    })
}
//删除场景保养信息
export function deleteSceneMaintain(params) {
    return http({
        url: `${preApi}/scene/deleteSceneMaintain`,
        method: "post",
        data: params
    })
}
//获取场景保养信息
export function getSceneMaintainListByCondition(params) {
    return http({
        url: `${preApi}/scene/getSceneMaintainListByCondition`,
        method: "post",
        data: params
    })
}
//获取场景运行数据
export function getSceneRunDataList(params) {
    return http({
        url: `${preApi}/scene/getSceneRunDataList`,
        method: "post",
        data: params
    })
}
//获取场景运行时间图
export function getSceneRunTimeCharts(params) {
    return http({
        url: `${preApi}/scene/getSceneRunTimeCharts`,
        method: "post",
        data: params
    })
}
